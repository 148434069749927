import moment from "moment";
import {
  getBooking,
  getUserBooking,
  getUserGuestBooking,
} from "../../../../../APIS/bookings";
import { useCustomQuery } from "../../../../../hooks/query";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { DIOM_BASED_URLS } from "../../../../../config/url";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCancelAdminGuestBookingByUserMutation } from "./useAvailableResourceMutation";
import { useQuery } from "react-query";

export const useUserGuestBookingDetail = () => {
  const token = localStorage.getItem("Token");
  const [isGuestBookingCheckoutCreated, setGuestBookingCheckoutCreated] =
    useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [pymentMethod, setPymentMethod] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState("");
  const history = useHistory();
  const { id } = useParams();
  const {
    data: userGuestBookingData,
    isLoading: isUserGuestBookingDataLoading,
  } = useCustomQuery(
    ["UserGuestBooking", id],
    () => getUserGuestBooking(id),
    true
  );

  //  Getting Booking Data By id..
  const confirmBookingId = userGuestBookingData?.data?.data?.bookingId;
  const bookingStatus = userGuestBookingData?.data?.data?.actionTaken;

  const bookingData = useQuery(
    ["UserConfirmBookingDetails", confirmBookingId],
    () => getUserBooking(confirmBookingId, history),
    {
      enabled: bookingStatus === "CONFIRM_BOOKING" ? true : false, // The query will only run if bookingId exists
    }
  );

  // Timer Logic
  useEffect(() => {
    const calculateRemainingTime = () => {
      const now = moment();
      const createdMoment = moment(userGuestBookingData?.data?.data.lockTime);
      const duration = moment.duration(createdMoment.diff(now));

      // Format the duration
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.minutes());
      const seconds = Math.floor(duration.seconds());

      return `${hours}h ${minutes}m ${seconds}s`;
    };

    // Set the initial remaining time
    if (userGuestBookingData?.data?.data?.lockTime) {
      setRemainingTime(calculateRemainingTime());

      // Update the remaining time every second
      const intervalId = setInterval(() => {
        setRemainingTime(calculateRemainingTime());
      }, 1000);

      // Cleanup the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [userGuestBookingData]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  // Function to format date and time
  const formatDateTime = (dateTime) => {
    return moment(dateTime).format("MMM DD YYYY, h:mm A");
  };

  // Function to calculate the difference in hours and minutes
  const calculateTimeDifference = (startTime, endTime) => {
    const start = moment(startTime);
    const end = moment(endTime);
    const duration = moment.duration(end.diff(start));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes() % 60);
    return `${hours} ${hours === 1 ? "hour" : "hours"} ${minutes} ${
      minutes === 1 ? "minute" : "minutes"
    }`;
  };

  function calculateTotalWithTax(amount) {
    const taxRate = 0.15; // 15% tax rate
    const tax = amount * taxRate; // Calculate tax

    return tax.toFixed(2);
  }

  // 2) Cancel Booking
  const {
    cancelAdminGuestBookingMutateAsync,
    isAdminGuestBookingCancelLoading,
  } = useCancelAdminGuestBookingByUserMutation(setIsOpen, "UserGuestBooking");

  function cancelHandler() {
    cancelAdminGuestBookingMutateAsync(id);
  }

  // Pay Now Modal handler

  function payNowHandler() {
    setIsPaymentModalOpen(true);
  }
  // Value Card Brand
  const STC_PAYMENT_BRAND = "STC_PAY";
  const VISA_MASTER_DB = "VISA MASTER";
  const VISA_MASTER_PAYMENT_BRAND_PA = "VISA MASTER";
  const MADA_PAYMENT_BRAND = "MADA";
  const paymentBrands =
    pymentMethod === "MADA"
      ? MADA_PAYMENT_BRAND
      : pymentMethod === "STC_PAY"
      ? STC_PAYMENT_BRAND
      : pymentMethod === "VISA_MASTER"
      ? VISA_MASTER_DB
      : pymentMethod === "APPLE_PAY"
      ? "APPLEPAY"
      : VISA_MASTER_PAYMENT_BRAND_PA;

  // Value for Card Payment Type
  const paymentType =
    pymentMethod === "MADA"
      ? "MADA_DB"
      : pymentMethod === "VISA_MASTER"
      ? "VISA_MASTER_DB"
      : pymentMethod === "APPLE_PAY"
      ? "APPLE_PAY_DB"
      : pymentMethod === "STC_Pay"
      ? "STC_DB"
      : "VISA_MASTER";

  async function createCheckOutForUserGuestBooking() {
    setGuestBookingCheckoutCreated(true);
    const response = await fetch(
      `${DIOM_BASED_URLS}/hyperpay/bookings/${id}/initiate-payment?cardBrand=${pymentMethod}&isGuestBooking=true`,
      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const data = await response.json();
    setGuestBookingCheckoutCreated(false);
    if (!data.statusCode === 200) {
      toast.error(`${data.message ?? " Something went wrong"}`);
    } else if (data.error) {
      toast.error(`${data.error.message ?? " Something went wrong"}`);
    } else {
      history.push(
        `/hayperpay-card-view?checkoutId=${data.data}&guestBookingId=${id}&brandData=${paymentBrands}&paymentType=${paymentType}`
      );
    }
  }

  return {
    userGuestBookingData,
    isUserGuestBookingDataLoading,
    formatDateTime,
    calculateTimeDifference,
    calculateTotalWithTax,
    createCheckOutForUserGuestBooking,
    isGuestBookingCheckoutCreated,
    toggle,
    dropdownOpen,
    isOpen,
    setIsOpen,
    cancelAdminGuestBookingMutateAsync,
    isAdminGuestBookingCancelLoading,
    cancelHandler,
    remainingTime,
    bookingData,
    payNowHandler,
    isPaymentModalOpen,
    setIsPaymentModalOpen,
    setPymentMethod,
    pymentMethod,
  };
};
