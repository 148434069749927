import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Input,
  FormGroup,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-flatpickr";
import { localeData } from "moment";
import Loader from "react-spinners/BounceLoader";
import MeetingRoomSelection from "./Components/MeetingRoomSelection";
import EventSpaceSelections from "./Components/EventSpaceSelections";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateAdminBooking } from "./hook/useCreateAdminBooking";
import * as yup from "yup";

const CreateAdminBooking = () => {
  const {
    userQuery,
    setUserQuery,
    userData,
    searchUserDataLoading,
    selectedUser,
    LocationData,
    isLocationDataLoading,
    setSelectedLocation,
    RTCOptions,
    selectedRTC,
    setRTC,
    accessTypeOptions,
    setAccessType,
    bookingDateHandler,
    startTimeHandler,
    endTimeHandler,
    availableResourceType,
    isCheckAvailableResourceType,
    locationHandler,
    resourceTypeCategoryHandler,
    rangeDateHandler,
    bookingDate,
    guestBookingHandler,
    selectResourceTypeHandler,
    EnableBtn,
    isAdminGuestBookingTypeLoading,
    isCreateUserModalOpen,
    setIsCreateUserModatOpen,
    toggle,
    closeBtn,
    onSubmit,
    isUserCreatedLoading,
    userSelectionHandler,
    bookingStartTime,
    bookingEndTime,
    selectedPassType,
    passTypeHandler,
    selectedLocation,
  } = useCreateAdminBooking();

  // Validation Schema
  const schema = yup.object().shape({
    firstName: yup.string().required("first name is required"),
    lastName: yup.string().required("last name is required"),
    phoneNumber: yup
      .string()
      .required("phone number is required")
      .matches(
        /^(009665|9665|\+9665)([0-9]{8})$/,
        "Phone number must be a valid Saudi number with country code +966"
      ),
  });

  // React Form.....
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <div className="page-content">
      <Row>
        <Col md={4}>
          <Link to="/request_bookings/1" className="link">
            <span
              className="fas fa-angle-left arrowheightwidth"
              style={{ color: "black" }}
            ></span>
          </Link>
          <span className="locationbrandtitle ml-2 ">Create Admin Booking</span>
        </Col>
      </Row>

      {/* Form */}
      <Card className="mt-3">
        <CardBody>
          <form>
            <Row className="mt-3">
              <Col md={4}>
                {" "}
                <Label
                  htmlFor="user"
                  className="logininboxpanels"
                  style={{ pointerEvents: "none" }}
                >
                  Search User <span className="cp_starik">*</span>
                </Label>
                <Select
                  value={[
                    { label: selectedUser?.label, value: selectedUser?.value },
                  ]}
                  isClearable={true}
                  isLoading={searchUserDataLoading}
                  placeholder="Select user"
                  onChange={(values) => userSelectionHandler(values)}
                  onInputChange={(query) => {
                    setUserQuery(query);
                  }}
                  options={userData.dropDownData}
                />
              </Col>
              <Col md={2}>
                <Button
                  color="success"
                  onClick={() => setIsCreateUserModatOpen(true)}
                  style={{
                    marginTop: "32px",
                    cursor: "pointer",
                    width: "150px",
                  }}
                >
                  Create New User
                </Button>
              </Col>
              <Col md={3}>
                {" "}
                <Label
                  htmlFor="location"
                  className="logininboxpanels"
                  style={{ pointerEvents: "none" }}
                >
                  Location <span className="cp_starik">*</span>
                </Label>{" "}
                <Select
                  placeholder="Select location"
                  isLoading={isLocationDataLoading}
                  options={LocationData.map((location) => ({
                    label: location.Name,
                    value: location.id,
                  }))}
                  onChange={(locationId) => {
                    locationHandler(locationId);
                  }}
                ></Select>{" "}
              </Col>
              <Col md={3}>
                <Label
                  htmlFor="location"
                  className="logininboxpanels"
                  style={{ pointerEvents: "none" }}
                >
                  Resource Type Category <span className="cp_starik">*</span>
                </Label>{" "}
                <Select
                  placeholder="Select RTC"
                  options={RTCOptions}
                  value={
                    selectedRTC?.name
                      ? [{ label: selectedRTC?.name, value: selectedRTC }]
                      : []
                  }
                  onChange={(RTCId) => resourceTypeCategoryHandler(RTCId.value)}
                ></Select>
              </Col>
            </Row>
            {/* Second Row */}
            <Row className="mt-5">
              {selectedRTC?.name === "Meeting Rooms" ? (
                <MeetingRoomSelection
                  bookingDateHandler={bookingDateHandler}
                  startTimeHandler={startTimeHandler}
                  endTimeHandler={endTimeHandler}
                  bookingStartTime={bookingStartTime}
                  bookingEndTime={bookingEndTime}
                  selectedRTC={selectedRTC}
                  accessTypeOptions={accessTypeOptions}
                  setAccessType={setAccessType}
                  bookingDate={bookingDate}
                />
              ) : selectedRTC?.name === "Inspiration Room" ? (
                <MeetingRoomSelection
                  bookingDateHandler={bookingDateHandler}
                  startTimeHandler={startTimeHandler}
                  endTimeHandler={endTimeHandler}
                  bookingStartTime={bookingStartTime}
                  bookingEndTime={bookingEndTime}
                  selectedRTC={selectedRTC}
                  accessTypeOptions={accessTypeOptions}
                  setAccessType={setAccessType}
                  bookingDate={bookingDate}
                />
              ) : selectedRTC?.name === "Board Room" ? (
                <MeetingRoomSelection
                  bookingDateHandler={bookingDateHandler}
                  startTimeHandler={startTimeHandler}
                  endTimeHandler={endTimeHandler}
                  bookingStartTime={bookingStartTime}
                  bookingEndTime={bookingEndTime}
                  selectedRTC={selectedRTC}
                  accessTypeOptions={accessTypeOptions}
                  setAccessType={setAccessType}
                  bookingDate={bookingDate}
                />
              ) : selectedRTC?.name === "Event Spaces" ? (
                <EventSpaceSelections
                  bookingDateHandler={bookingDateHandler}
                  startTimeHandler={startTimeHandler}
                  endTimeHandler={endTimeHandler}
                  bookingStartTime={bookingStartTime}
                  bookingEndTime={bookingEndTime}
                  selectedRTC={selectedRTC}
                  accessTypeOptions={accessTypeOptions}
                  setAccessType={setAccessType}
                  bookingDate={bookingDate}
                />
              ) : selectedRTC?.name === "Shared Spaces" ? (
                <>
                  <Col md={3}>
                    <Label
                      htmlFor="access_type_meeting_room"
                      className="logininboxpanels"
                      style={{ pointerEvents: "none" }}
                    >
                      Access Type <span className="cp_starik">*</span>
                    </Label>

                    <Select
                      placeholder="Select"
                      id="access_type_meeting_room"
                      options={
                        selectedRTC
                          ? accessTypeOptions
                          : [{ value: "Pass", label: "Pass" }]
                      }
                      defaultValue={[{ value: "Pass", label: "Pass" }]}
                      onChange={(accessType) => {
                        setAccessType(accessType);
                      }}
                    ></Select>
                  </Col>
                  <Col md={3}>
                    <Label
                      htmlFor="pass_type_shared_space"
                      className="logininboxpanels"
                      style={{ pointerEvents: "none" }}
                    >
                      Pass Type <span className="cp_starik">*</span>
                    </Label>
                    <Select
                      placeholder="Select"
                      onChange={passTypeHandler}
                      id="pass_type_shared_space"
                      options={[
                        { value: "Daily", label: "Daily" },
                        { value: "Hourly", label: "Hourly" },
                      ]}
                      defaultValue={[{ value: "Daily", label: "Daily" }]}
                    ></Select>
                  </Col>
                  <Col md={2}>
                    <Label
                      htmlFor="booking_date_share_space"
                      className="logininboxpanels"
                      style={{ pointerEvents: "none" }}
                    >
                      Booking Date <span className="cp_starik">*</span>
                    </Label>
                    <DatePicker
                      value={bookingDate}
                      name="bookingDateRangeForShareSpace"
                      onChange={(date) => {
                        rangeDateHandler(date);
                      }}
                      className="datepickercss_exceptions"
                      placeholder={"Select date"}
                      options={{
                        mode:
                          selectedPassType === "Hourly" ? "single" : "range",
                        minDate: "today",
                      }}
                      id="booking_date_share_space"
                    />
                  </Col>
                  {bookingDate && selectedPassType === "Hourly" ? (
                    <>
                      <Col md={2}>
                        <Label
                          htmlFor="start_time_meeting_room"
                          className="logininboxpanels"
                          style={{ pointerEvents: "none" }}
                        >
                          Check-in Time <span className="cp_starik">*</span>
                        </Label>{" "}
                        <DatePicker
                          onChange={(date) => startTimeHandler(date)}
                          name="startTime"
                          value={bookingStartTime}
                          // {...register("endDate")}
                          className="datepickercss_exceptions"
                          placeholder={"Select start time"}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            // time_24hr: true,
                            minuteIncrement: 15,
                          }}
                          id="start_time_meeting_room"
                        />
                      </Col>
                      <Col md={2}>
                        <Label
                          htmlFor="end_time_meeting_room"
                          className="logininboxpanels"
                          style={{ pointerEvents: "none" }}
                        >
                          Check-out Time <span className="cp_starik">*</span>
                        </Label>
                        <DatePicker
                          onChange={(date) => endTimeHandler(date)}
                          name="endTime1"
                          value={bookingEndTime}
                          className="datepickercss_exceptions"
                          placeholder={"Select end time"}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            minuteIncrement: 15,
                          }}
                          id="end_time_meeting_room"
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : selectedRTC?.name === "Private Offices" ? (
                <p className="ml5"> Membership in progress</p>
              ) : (
                ""
              )}
            </Row>
            {/* Third Row */}

            {isCheckAvailableResourceType ? (
              <Spinner style={{ marginTop: "10px" }}>Loading...</Spinner>
            ) : (
              <Row className="mt-4">
                <Col md={8}>
                  <>
                    {availableResourceType?.length > 0 && (
                      <p
                        className=" ml-2 "
                        style={{
                          fontSize: "23px",
                          fontWeight: 400,
                          fontStyle: "normal",
                        }}
                      >
                        Available Resource Type
                      </p>
                    )}
                    {availableResourceType === null
                      ? ""
                      : availableResourceType.length > 0
                      ? availableResourceType?.map((RT) => {
                          return (
                            <FormGroup check key={RT.id}>
                              <Input
                                name="radio1"
                                type="radio"
                                onClick={() => selectResourceTypeHandler(RT)}
                              />
                              <Label check>{RT.Name}</Label>
                            </FormGroup>
                          );
                        })
                      : "No resourceType found at given time"}
                  </>
                </Col>
              </Row>
            )}

            {/* Create Booking Btn Row */}
            <Row>
              <Col md={4} className="mt-5">
                <div>
                  <Button
                    disabled={EnableBtn || isAdminGuestBookingTypeLoading}
                    style={{ width: "350px" }}
                    className="btn btn-success "
                    onClick={() => guestBookingHandler()}
                  >
                    {isAdminGuestBookingTypeLoading ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "Create Booking"
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>

      {/* Create User Modal */}
      <Modal isOpen={isCreateUserModalOpen} toggle={toggle} size="md">
        <ModalHeader toggle={toggle} close={closeBtn}>
          Create new user
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-3">
              <Col md={12}>
                <Label
                  htmlFor="First Name"
                  className="logininboxpanels"
                  style={{ pointerEvents: "none" }}
                >
                  First Name <span className="cp_starik">*</span>
                </Label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    {...register("firstName", {
                      required: true,
                      maxLength: 8,
                    })}
                    className={`form-control ${
                      errors.firstName ? "is-invalid" : ""
                    }`}
                    placeholder="Enter first name"
                    id="First Name"
                  />
                  {errors.firstName && (
                    <div className="invalid-feedback">
                      {errors.firstName.message}
                    </div>
                  )}
                </div>
              </Col>

              <Col md={12}>
                <Label
                  htmlFor="Enter last name"
                  className="logininboxpanels"
                  style={{ pointerEvents: "none" }}
                >
                  Last Name <span className="cp_starik">*</span>
                </Label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    {...register("lastName", {
                      required: true,
                      maxLength: 20,
                    })}
                    className={`form-control ${
                      errors.lastName ? "is-invalid" : ""
                    }`}
                    placeholder="Enter last name "
                    id="Enter last name"
                  />
                  {errors.lastName && (
                    <div className="invalid-feedback">
                      {errors.lastName.message}
                    </div>
                  )}
                </div>
              </Col>

              <Col md={12}>
                <Label htmlFor="phoneNumber" className="logininboxpanels">
                  PhoneNumber <span className="cp_starik">*</span>
                </Label>
                <div className="input-group mb-3">
                  <input
                    type="number"
                    {...register("phoneNumber")}
                    className={`form-control ${
                      errors.phoneNumber ? "is-invalid" : ""
                    }`}
                    placeholder="Enter phone number"
                    id="phoneNumber"
                  />
                  {errors.phoneNumber && (
                    <div className="invalid-feedback">
                      {errors.phoneNumber.message}
                    </div>
                  )}
                </div>
              </Col>
              {/* <Col md={6}>
                <Label htmlFor="password" className="logininboxpanels">
                Password <span className="cp_starik">*</span>
                </Label>
                <div className="input-group mb-3">
                  <input
                    type="password"

                    className={`form-control`}
                    // {...register("password")}
                    // className={`form-control ${
                    //   errors.Password ? "is-invalid" : ""
                    // }`}
                    placeholder="Enter Password"
                    id="password"
                  />
                  {errors.Password && (
                    <div className="invalid-feedback">
                      {errors.Password.message}
                    </div>
                  )}
                </div>
              </Col> */}
            </Row>
            <Row>
              <Col md={12}>
                <div className="mt-3 mb-3 ">
                  <button
                    disabled={isUserCreatedLoading}
                    style={{ width: "100%" }}
                    type="submit"
                    className="btn btn-success "
                  >
                    {isUserCreatedLoading ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreateAdminBooking;
